@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600&display=swap');


$font-stack: 'Quicksand', sans-serif;
$bg-primary-color: rgb(255, 255, 255);
$bg-secend-color: rgb(211, 211, 211);
$font-first-color: rgb(39, 39, 39);
$font-secend-color: rgb(255, 255, 255);
$font-third-color: rgb(128, 128, 128);




  @mixin respond($breakpoint) {
    @if($breakpoint == medium)  {
      @media (max-width: 1200px) { @content }
    }
    @if($breakpoint == small)  {
      @media (max-width: 576px) { @content }
    }
  }