@import "base";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    background: $bg-primary-color;
    font-family: $font-stack;
    color: $font-first-color;
    margin: 0;
    padding: 0;
}

.menu-nav {
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include respond(small) {
            flex-wrap: wrap;
    }
    .logo {
        display: inline-block;
        width: 50px;
        height: 50px;
    }
    .nav-links {
        list-style: none;
        display: flex;
        .nav-item {
            font-size: 1.2rem;
            .nav-ico {
                height: 16px;
                color: $font-first-color;
                display: inline-block;
                padding: 15px;
                text-decoration: none;
                transition: 0.5s ;
                [title]:hover::after {
                   color: red;
                }
                &:hover,
                &:focus {
                   opacity: 0.4;
                }
            }
        }
    }
}

.content-box {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    grid-gap: 0;
    justify-content: stretch;
    @include respond(small) {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
    .mgModal {
        padding: 0;
        background-color: $bg-secend-color;
        color: $font-secend-color;
        overflow: hidden;
        cursor: pointer;
        &:hover .button-fantom,
        &:focus .button-fantom {
            transform: scale(1.2);
            transition: all 0.3s;
            overflow: hidden;
            &::before {
                display: block;
            }
            &:hover p,
            &:focus p {
                display: block;
            }
        }
        .button-fantom {
            padding-bottom: 100%;
            display: block;
            text-align: center;
            position: relative;
            background-position: center;
            background-size: cover;
            transform: scale(1);
            transition: all 0.3s;
            &::before {
                content: "";
                display: none;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(31, 31, 31, 0.9);
            }
            .thumbnail-header {
                position: absolute;
                font-size: 1.5rem;
                height: 10%;
                top: 45%;
                bottom: 45%;
                width: 100%;
                display: none;
            }
            .thumbnail-text {
                position: absolute;
                font-size: 1rem;
                height: 10%;
                top: 55%;
                // bottom: 25%;
                width: 100%;
                display: none;
            }
        }
        .modal {
            display: none;
            position: fixed;
            z-index: 1;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            overflow: auto;
            background-color: rgba(0, 0, 0, 0.4);
            transition: 1s;
            cursor: default;
            .modal-content {
                background: $bg-primary-color;
                width:100%;
                // margin-left: auto;
                color: $font-first-color;
                @include respond(medium) {
                    width: 100%;
                }
                .data-box {
                    padding-left: 64px;
                    padding-top: 16px;
                    padding-right: 64px;
                    @include respond(small) {
                        padding: 0;
                    }
                    .projekt-info {
                        font-size: 1rem;
                        padding: 16px;
                        color: $font-third-color;
                        margin-bottom: 70px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: start;
                        @include respond(small) {
                            padding: 32px;
                        }
                        p {
                            width: 100%;
                            font-size: 1rem;
                            line-height: 2rem;
                            display: inline-block;
                            margin-right: 15px;
                        }
                        a {
                            color: $font-third-color;
                        }
                        .modal-name {
                            font-size: 1.5rem;
                            font-weight: 600;
                            color: $font-first-color;
                            display: inline-block;
                        }
                        .modal-title, .modal-date {
                            width: auto;
                            font-size: 0.8rem;
                        }
                        .modal-text {
                            color: $font-first-color;
                            margin-top: 32px;
                            padding-right: 40%;
                            @include respond(small) {
                                margin-top: 32px;
                                padding-right: 0;
                            }
                        }
                        .close {
                            text-align: right;
                            font-size: 3.5rem;
                            color: $font-third-color;
                            // float: right;
                            cursor: pointer;
                            margin-left: 100%;
                            @include respond(small) {
                                margin-left: 90%;
                            }
                        }
                    }

                }
                .modal-images img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }
    }
}
